import { useState, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Route, Redirect } from 'react-router-dom'

import { Header, Segment, Grid, Modal, Divider, List } from 'semantic-ui-react'
import { connect } from 'react-redux'
import AssociateHeader from 'components/Header/AssociateHeader'
import AssociateSideBar from '../Sidebar/AssociateSideBar'

const PrivateAssociateRoute = (props) => {
	const {
		isAuthenticated = true,
		component: Component,
		isAssociateAuthenticated,
		...rest
	} = props //.l.
	const [expand, setExpand] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [mobileOnly, setMobileOnly] = useState(false)
	const [previousExpand, setPreviousExpand] = useState(null)
	const modules = [
		{
			name: 'Administrador de Catálogos',
			icon: 'archive',
			module_group: 'SuperAdmin',
			url: 'process_catalog',
			// sub_group: 'module?.sub_group',
			isVisible: props.associate?.super_admin,
			permisitions: { ACCESS: props.associate?.super_admin },
		},
		{
			name: 'Administrador de Clientes',
			icon: 'archive',
			module_group: 'Clientes',
			url: 'sys_company_administrator',
			isVisible: true,
			permisitions: { ACCESS: true },
		},
		{
			name: 'Socios',
			icon: 'users',
			module_group: 'SuperAdmin',
			url: 'associates',
			isVisible: props.associate?.super_admin,
			permisitions: { ACCESS: props.associate?.super_admin },
		},
		{
			name: 'Wizard',
			icon: 'archive',
			module_group: 'SuperAdmin',
			url: 'wizards_superAdmin',
			isVisible: true,
			permisitions: { ACCESS: props.associate?.super_admin },
		},
		{
			name: 'Formatos de Impresión',
			icon: 'archive',
			module_group: 'SuperAdmin',
			url: 'impression_formats_superAdmin',
			isVisible: true,
			permisitions: { ACCESS: props.associate?.super_admin },
		},
		{
			name: 'Procedimientos',
			icon: 'archive',
			module_group: 'SuperAdmin',
			url: 'procedures_superAdmin',
			isVisible: true,
			permisitions: { ACCESS: props.associate?.super_admin },
		},
	]
	return (
		<>
			{isAssociateAuthenticated > 0 ? (
				<Route
					{...rest}
					render={(props) => (
						<Fragment>
							<AssociateHeader
								rest={rest}
								logout={rest.logout}
								history={props.history}
								expand={expand}
								setExpand={setExpand}
								previousExpand={previousExpand}
								setPreviousExpand={setPreviousExpand}
								openModal={openModal}
								setOpenModal={setOpenModal}
								mobileOnly={mobileOnly}
								setMobileOnly={setMobileOnly}
							/>
							<div
								className='main-content'
								style={
									expand == null || expand == 2
										? { marginLeft: '250px' }
										: expand == 1
										? { marginLeft: '55px' }
										: null
								}
							>
								{ReactDOM.createPortal(
									<AssociateSideBar
										expand={expand}
										setExpand={setExpand}
										user={{ modules }}
										mobileOnly={mobileOnly}
										setMobileOnly={setMobileOnly}
										{...props}
									/>,
									document.getElementById('root')
								)}
								<Component {...props} />
							</div>
						</Fragment>
					)}
				/>
			) : (
				<Redirect to='/login_superadmin' />
			)}
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
	isAssociateAuthenticated: !!state.app?.superAdmin?.associate,
	associate: state.app?.superAdmin?.associate,
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrivateAssociateRoute)
