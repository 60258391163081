// import React, { useEffect, useMemo, useState,useReducer } from 'react'
import React, { useEffect, useMemo, useState, useReducer } from 'react'
import PaginatedTable from 'semantic-table/dist/components/PaginatedTable'
import {
	Breadcrumb,
	Tab,
	Dimmer,
	Loader,
	Segment,
	Checkbox,
	Button,
	Table,
	Modal,
	Dropdown,
	Popup,
	Select,
	Icon,
	Radio,
	Grid,
	Image,
} from 'semantic-ui-react'
import { callApi } from '../../utils/api'
import { connect } from 'react-redux'
import module_group_relActions from '../../store/actions/module_group_rel'
import { getStateValue } from 'store/selectors'
const sections = [
	{ key: 'Administrador de Wizard', content: 'Administrador de Wizard' },
]

const WizardCatalog = (props) => {
	const [isFetching, setIsFetching] = useState(false)
	const { superAdmin, getModuleGroupRel, module_group_rels } = props
	const { sysModules } = superAdmin

	console.log('props', props)

	useEffect(() => {
		getModuleGroupRel()
	}, [])

	return (
		<div className='PageContainer'>
			<Breadcrumb
				icon='right angle'
				style={{ color: '#000' }}
				sections={sections}
			/>
			<PaginatedTable
				title='Administrador de Catálogos'
				loading={isFetching}
				rows={module_group_rels}
				onSelect={(row) => {
					props.history.push(`/wizards_superAdmin/${row.id}`)
				}}
				onAdd={() => {
					props.history.push(`/wizards_superAdmin/new`)
				}}
				enableExportToCSV={true}
				onCancel={true}
				onSave={(data) => {
					props.bulkSync(data)
				}}
				actionsWidth={80}
				actionsActive={true}
				paginated={false}
				columns={[
					{
						width: 175,
						Header: 'Módulo',
						accessor: 'module_group',
						filterable: true,
						sortable: true,
					},
				]}
			/>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	//loadTours: (options) => dispatch(tourActions.load(options)),
	// bulkSync: (options) => dispatch(sys_modulesActions.bulkSync(options)),
	// loadSysModules: (options) => dispatch(sys_modulesActions.load(options))
	getModuleGroupRel: (options) =>
		dispatch(module_group_relActions.getModuleGroupRel(options)),
})

const mapStateToProps = (state) => ({
	//tours: getStateValue(state.tours.entities),
	// sys_modules: getStateValue(state.sys_modules.entities),
	module_group_rels: getStateValue(state.module_group_rels.entities),
	superAdmin: state.app.superAdmin,
})

export default connect(mapStateToProps, mapDispatchToProps)(WizardCatalog)
