import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { logout, setLanguage } from 'store/actions/app'
import queryString from 'query-string'
import moment from 'moment'

import { callApi } from 'utils/api'
import { getStateValue } from 'store/selectors'
import documentItemActions from 'store/actions/documents_items'
import serviceOrderActions from '../../store/actions/service_orders'
import './Header.scss'
import sysModuleRelActions from '../../store/actions/sys_module_rel'

import {
	Message,
	Button,
	Menu,
	Icon,
	Popup,
	Image,
	Grid,
	Label,
	Header,
	Segment,
	Dropdown,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import styles from './../../styles/global.module.scss'

const getAvatar = (user, client) => {
	if (user?.photo && user?.photo.length > 0) {
		return user.photo
	} else {
		let name = 'Guest'
		if (user) {
			name = user?.entity?.name ?? user?.username
		}
		if (client) {
			name = client?.code
		}
		return `https://ui-avatars.com/api/?name=${name}`
	}
}

const HeaderComponent = (props) => {
	const {
		user,
		client,
		rest,
		logout,
		history,
		expirations,
		employees,
		setExpand,
		openModal,
		setOpenModal,
		mobileOnly,
		previousExpand,
		setPreviousExpand,
		service_orders,
	} = props

	const [modalVisible, setModalVisible] = useState(false)
	const [employes, setEmployees] = useState([])
	const [hasEmployeeRights, setHasEmployeeRights] = useState(false)
	const [canCheckLicense, setCanCheckLicense] = useState(false)
	const [hasDocumentsRights, setHasDocumentsRights] = useState(false)
	const [notificationsCount, setNotificationsCount] = useState(0)
	const [listServiceOrders, setListServiceOrders] = useState([])
	const [pendingOrders, setPendingOrders] = useState([])
	const [delayedOrders, setDelayedOrders] = useState([])
	const [waitingOrders, setWaitingOrders] = useState([])
	const [sysModuleRels, setSysModuleRels] = useState([])

	useEffect(() => {
		const module = props?.user?.modules?.find((m) => {
			if (m.url !== '') {
				const url = props?.history?.location?.pathname?.substr(1)
				return url === m.url || url.startsWith(`${m.url}/`)
			}
			return false
		})
		if (module?.id) {
			const func = async () => {
				const data = await props.getSysModulesRel(module?.id)
				setSysModuleRels(data)
			}
			func()
		}
	}, [props?.user?.modules, props?.history?.location?.pathname])

	const moduleRelsOpts = useMemo(() => {
		return props?.user?.modules
			?.filter((m) => {
				return !!sysModuleRels.find(({ id_acceso }) => id_acceso == m.id)
			})
			.map((m) => ({
				key: m.name,
				text: (
					<div
						onClick={() => {
							window.open(`/${m.url}`, '_blank')
						}}
					>
						<Menu.Item
							className={styles.growOnHover}
							style={{ margin: '0', color: 'black' }}
						>
							{m.name}
						</Menu.Item>
					</div>
				),
			}))
	}, [sysModuleRels])

	const processOpts = useMemo(() => {
		return props?.user?.last_visits?.map((m) => ({
			key: m.name,
			text: (
				<Link
					to={`/${m.url}`}
					className={styles.growOnHover}
					style={{ margin: '0', color: 'black' }}
				>
					<Menu.Item
						className={styles.growOnHover}
						style={{ margin: '0', color: 'black' }}
					>
						{m.name}
					</Menu.Item>
				</Link>
			),
		}))
	}, [props?.user])

	const componentDidMount = async () => {
		//const startOfMonth = moment().subtract(45, 'days').format('YYYY-MM-DD');
		const endOfMonth = moment().add(45, 'days').format('YYYY-MM-DD')
		let res = await callApi(
			`/employee?license_expiration_date.lte=${endOfMonth}&active.eq=1`,
			{
				method: 'GET',
				headers: {
					Authorization: user.token,
				},
			}
		)
		setEmployees(res.body)

		// let list = await props.loadAndUpdate()
		// setListServiceOrders(list)
	}
	useEffect(() => {
		setPendingOrders(listServiceOrders.filter((o) => o.status === 'PENDIENTE'))
		setDelayedOrders(
			listServiceOrders.filter((order) => order.status === 'ATRASADA')
		)
		setWaitingOrders(
			listServiceOrders.filter((order) => order.status === 'EN ESPERA')
		)
	}, [listServiceOrders])

	useEffect(() => {
		hasDocumentsRights && props.loadExpirationReport()
	}, [hasDocumentsRights])

	useEffect(() => {
		setHasDocumentsRights(user.modules.findIndex((m) => m.id === 102) > -1)
	}, [props.user])

	useEffect(() => {
		let count = employes?.length ?? 0
		count += expirations?.Documents?.length ?? 0
		count += expirations?.TrafficTickets?.length ?? 0
		count += expirations?.FastCards?.length ?? 0
		count += pendingOrders?.length ?? 0
		count += delayedOrders?.length ?? 0
		count += waitingOrders?.length ?? 0
		setNotificationsCount(count)
	}, [employes, expirations, pendingOrders, delayedOrders, waitingOrders])

	useEffect(() => {
		let employeePermissions = user.modules.find(
			(m) => m.url === 'employees'
		)?.permisitions
		if (employeePermissions?.ACCESS) {
			componentDidMount()
		}
		setCanCheckLicense(employeePermissions)
	}, [employees])

	const logoutModal = () => {
		localStorage.setItem('showModal', 'true')
	}
	let userName = rest.user?.username

	return (
		<Menu
			className='NavBar__Header'
			inverted
			borderless
			size='tiny'
			attached='top'
			fixed='top'
		>
			<Menu.Item className='NavBar__Item'>
				<Icon
					className='NavBar__Outline'
					name='list'
					size='large'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setExpand((previousExpandedState) => {
							setPreviousExpand(previousExpandedState)
							if (!mobileOnly)
								return previousExpandedState == 1
									? previousExpand == null || previousExpand == 2
										? 0
										: 2
									: 1
							return !previousExpandedState ? 1 : 0
						})
					}}
				/>
			</Menu.Item>
			<Menu className='SubMenu__Header' size='mini'>
				<Menu.Item
					//textAlign='center'
					//verticalAlign='middle'
					//className='Logout__Item'
					onClick={() => {
						window.location.href = 'https://app.geologisticerp.com/'
					}}
				>
					<Image
						style={{ width: 25, height: 25 }}
						src={props.user.company.menu_logo_url}
						size='small'
						circular
					/>
					{/* <a href='http://localhost:3000/'> */}
					{/* </a> */}
				</Menu.Item>
				<Menu.Item fitted position='right'>
					<Icon
						className='Circle_Icon NavBar__Item'
						name='question'
						circular
						onClick={() => {
							setOpenModal(!openModal)
						}}
					/>
				</Menu.Item>
			</Menu>

			<Menu.Menu position={mobileOnly ? null : 'right'} size='tiny'>
				<Menu.Item>
					<Dropdown placeholder='Accesos Directos' options={processOpts} />
				</Menu.Item>
				<Menu.Item>
					<Button
						primary
						style={{ backgroundColor: '#003366', color: '#fff' }}
						onClick={() => {
							window.open('/procedures', '_blank')
						}}
					>
						Procedimientos
					</Button>
				</Menu.Item>
				<Menu.Item>
					<Dropdown placeholder='Catálogos' options={moduleRelsOpts} />
				</Menu.Item>
				<Menu.Item position='right' fitted className='NavBar__Item'>
					{(canCheckLicense || hasDocumentsRights) && (
						<Popup
							pinned
							trigger={
								<Menu.Item style={{ marginRight: '1vw', padding: '0 0.8em' }}>
									<Icon
										className='NavBar__Outline'
										size='big'
										name='bell'
										fitted
									>
										{notificationsCount > 0 && (
											<Label
												color='red'
												floating
												circular
												style={{
													fontFamily:
														"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
													fontSize: '0.45em',
												}}
											>
												{notificationsCount}
											</Label>
										)}
									</Icon>
								</Menu.Item>
							}
							content={
								<div>
									{notificationsCount == 0 && (
										<Message
											size='tiny'
											warning
											icon='info'
											header={`No hay notificaciones`}
										/>
									)}
									{employes.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/employees',
													search: queryString.stringify({
														filter: employes.map((e) => e.id).join(','),
													}),
												})
											}}
											warning
											icon='warning'
											header={`${employes.length} licencia${
												employes.length > 1 ? 's' : ''
											} vence${
												employes.length > 1 ? 'n' : ''
											} en menos de 45 días`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.Documents?.filter(
										(it) => it.actual_status == 'POR VENCER'
									).length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/document_items',
													search: queryString.stringify({
														por_vencer: true,
													}),
												})
												window.location.reload() // Recargar la página
											}}
											warning
											icon='warning'
											header={`${
												expirations.Documents?.filter(
													(it) => it.actual_status == 'POR VENCER'
												).length
											} documento${
												expirations.Documents?.filter(
													(it) => it.actual_status == 'POR VENCER'
												).length > 1
													? 's'
													: ''
											} por vencer`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.Documents?.filter(
										(it) => it.actual_status == 'VENCIDO'
									).length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/document_items',
													search: queryString.stringify({
														vencidos: true,
													}),
												})
												window.location.reload() // Recargar la página
											}}
											warning
											color='red'
											icon='warning'
											header={`${
												expirations.Documents?.filter(
													(it) => it.actual_status == 'VENCIDO'
												).length
											} documento${
												expirations.Documents?.filter(
													(it) => it.actual_status == 'VENCIDO'
												).length > 1
													? 's expirados'
													: ' expirado'
											}`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.TrafficTickets?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/documents_expirations',
													search: queryString.stringify({
														tabId: 1,
													}),
												})
											}}
											warning
											icon='warning'
											header={`${expirations.TrafficTickets?.length} multa${
												expirations.TrafficTickets?.length > 1 ? 's' : ''
											} por vencer`}
											content='Click aquí para ver detalles'
										/>
									)}

									{expirations.FastCards?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/documents_expirations',
													search: queryString.stringify({
														tabId: 2,
													}),
												})
											}}
											warning
											icon='warning'
											header={`${expirations.FastCards?.length} Tarjeta${
												expirations.FastCards?.length > 1 ? 's' : ''
											} Fast por vencer`}
											content='Click aquí para ver detalles'
										/>
									)}
									{waitingOrders?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/workshop/service_order',
													search: queryString.stringify({
														filter: waitingOrders.map((e) => e.id).join(','),
													}),
												})
											}}
											warning
											icon='warning'
											header={`${waitingOrders?.length} Orden${
												waitingOrders?.length > 1 ? 'es' : ''
											} en estatus EN ESPERA`}
											content='Click aquí para ver detalles'
										/>
									)}
									{pendingOrders?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/workshop/service_order',
													search: queryString.stringify({
														filter: pendingOrders.map((e) => e.id).join(','),
													}),
												})
											}}
											color='orange'
											icon='warning'
											header={`${pendingOrders?.length} Orden${
												pendingOrders?.length > 1 ? 'es' : ''
											} en estatus PENDIENTE${
												pendingOrders?.length > 1 ? 'S' : ''
											}`}
											content='Click aquí para ver detalles'
										/>
									)}
									{delayedOrders?.length > 0 && (
										<Message
											size='tiny'
											onClick={() => {
												history.replace({
													pathname: '/workshop/service_order',
													search: queryString.stringify({
														filter: delayedOrders.map((e) => e.id).join(','),
													}),
												})
											}}
											color='red'
											icon='warning'
											header={`${delayedOrders?.length} Orden${
												delayedOrders?.length > 1 ? 'es' : ''
											} en estatus ATRASADA${
												delayedOrders?.length > 1 ? 'S' : ''
											}`}
											content='Click aquí para ver detalles'
										/>
									)}
								</div>
							}
							position='bottom right'
							on='click'
						/>
					)}
				</Menu.Item>

				<Menu.Item fitted position='right' className='NavBar__Item'>
					<Popup
						onOpen={() => setModalVisible(true)}
						onClose={() => setModalVisible(false)}
						pinned
						on='click'
						trigger={
							<Menu.Item className='NavBar__Item'>
								<Image src={getAvatar(user, client)} avatar />
							</Menu.Item>
						}
						content={
							<Segment className='NavBar__Outline'>
								<Grid
									divided='vertically'
									columns='equal'
									className='Header_PopUp'
									style={{ display: 'inline-block', padding: 0 }}
								>
									<Grid.Row columns={1}>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Image
												className='Header_PopUp_Image'
												src={props.user.company.menu_logo_url}
												avatar
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											// className='Logout__Item'
										>
											<Dropdown
												placeholder='Seleccione un idioma.'
												value={props.lang ?? 'es'}
												onChange={(e, { value }) => {
													history.push(`?lang=${value}`)
													props.setLanguage(value)
												}}
												options={[
													{
														key: 'es',
														value: 'es',
														text: 'Español',
														flag: 'mx',
													},
													{
														key: 'en',
														value: 'en',
														text: 'English',
														flag: 'us',
													},
													{
														key: 'pt',
														value: 'pt',
														text: 'Português',
														flag: 'pt',
													},
													{ key: 'ar', value: 'ar', text: 'عرب', flag: 'uae' },
												]}
												style={{ color: 'black' }}
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<h4 className='NavBar__InvertedOutline'>
												{userName.toUpperCase()}
											</h4>
										</Grid.Column>

										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Menu.Item
												textAlign='center' // Add this line to center the content
												verticalAlign='middle'
												className='Logout__Item'
												onClick={() => {
													window.open(
														`https://docs.google.com/document/d/15aFvfideG8cAsBik3pTpRpwsqlHmgsLZvGaCbrXU30Y/edit?usp=sharing`
													)
												}}
											>
												<Header>
													<h4>Actualización</h4>
													<Header.Subheader>V 0.17.69</Header.Subheader>
												</Header>
											</Menu.Item>
										</Grid.Column>

										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Button
												className='Header_PopUp_Button'
												basic
												icon
												labelPosition='right'
												onClick={() => {
													logout()
													logoutModal()
													props.history.push(
														`/login${
															user?.company?.logo_token
																? '?e=' + user?.company?.logo_token
																: ''
														}`
													)
												}}
											>
												<Icon name='sign out' />
												Cerrar sesión
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						}
						position='bottom right'
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

const mapDispatchToProps = (dispatch) => ({
	getSysModulesRel: (id) => dispatch(sysModuleRelActions.getSysModuleRel(id)),
	loadExpirationReport: (opt) =>
		dispatch(documentItemActions.loadExpirationReport(opt)),
	loadServiceOrders: (options) => dispatch(serviceOrderActions.load(options)),
	loadAndUpdate: (options) =>
		dispatch(serviceOrderActions.loadAndUpdate(options)),
	logout: () => dispatch(logout()),
	setLanguage: (lang) => dispatch(setLanguage(lang)),
})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user,
	isClientAuthenticated: !!state.app.client,
	user: state.app.user,
	client: state.app.client,
	employees: state.employees,
	service_orders: getStateValue(state.service_orders.entities),
	expirations: state.document_items?.expiration_report ?? [],
	lang: state.app?.lang,
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
